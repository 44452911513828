import { call, put, takeEvery, all } from 'redux-saga/effects'

import { getJSON } from '../../libs/data'
import * as types from './types'

function* initOptions(action) {
  const url = '/api/v1/user/init'
  yield put({ type: types.GET_OPTIONS_PENDING })
  try {
    const payload = yield call(getJSON, url, { req: action.req })
    yield put({ type: types.GET_OPTIONS_SUCCESS, payload })
  } catch (error) {
    yield put({
      type: types.GET_OPTIONS_REJECTED,
      errorCode: error.statusCode,
      errorMessage: error.response && error.response.message ? error.response.message : '',
    })
  }
}

export default function* optionsSagas() {
  yield all([takeEvery(types.GET_OPTIONS, initOptions)])
}
