import * as types from './ArtistsTypes'

export const getArtists = (sortField = 'name', sortDir = 'desc', q = '') => ({
  type: types.GET_ALL_ARTISTS,
  sortField,
  sortDir,
  q,
})
export const getArtistsByStatus = (status, sortField = 'name', sortDir = 'desc', q = '') => ({
  type: types.GET_ALL_ARTISTS_BY_STATUS,
  status,
  sortField,
  sortDir,
  q,
})
export const getArtistCountsByStatus = () => ({ type: types.GET_COUNT_ARTISTS_STATUS })
export const draftArtistAction = (id, status) => ({ type: types.DRAFT_ARTIST, id, status })
export const approveArtist = (id, status) => ({ type: types.APPROVE_ARTIST, id, status })
export const unapproveArtist = (id, status) => ({ type: types.UNAPPROVE_ARTIST, id, status })
export const declineArtist = (id, status) => ({ type: types.DECLINE_ARTIST, id, status })
export const promoteArtist = (id, status) => ({ type: types.PROMOTE_ARTIST, id, status })
export const unpromoteArtist = (id, status) => ({ type: types.UNPROMOTE_ARTIST, id, status })

export const getArtistById = id => ({ type: types.GET_ONE_ARTIST, id })
