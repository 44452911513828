import { createSelector } from 'reselect'
import * as types from './types'

const initialState = {
  loaded: false,
  instruments: {},
  categories: {},
  eventTypes: {},
  artistTypes: {},
  genres: {},
}

const optionsReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_OPTIONS_SUCCESS:
      if (!action.payload || !action.payload.options) {
        return {
          ...initialState,
        }
      }
      return {
        loaded: true,
        ...action.payload.options,
      }
    case types.GET_OPTIONS_REJECTED: {
      return {
        ...initialState,
      }
    }
    default: {
      return state
    }
  }
}

export default optionsReducer

const getOptionsState = state => state.options

export const getOptionsLoadedSelector = createSelector(
  getOptionsState,
  state => state.loaded,
)

export const getCategoriesSelector = createSelector(
  getOptionsState,
  state => state.categories,
)

export const getInstrumentsSelector = createSelector(
  getOptionsState,
  state => state.instruments,
)

export const getEventTypesSelector = createSelector(
  getOptionsState,
  state => state.eventTypes,
)

export const getArtistTypesSelector = createSelector(
  getOptionsState,
  state => state.artistTypes,
)

export const getGenresSelector = createSelector(
  getOptionsState,
  state => state.genres,
)
