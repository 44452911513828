import { combineReducers } from 'redux'

import currentUserReducer from './CurrentUser/CurrentUserReducer'
import usersReducer from './Users/UsersReducer'
import artistsReducer from './Artists/ArtistsReducer'
import gigRequestsReducer from './GigRequests/GigRequestsReducer'
import reviewsReducer from './Reviews/ReviewsReducer'
import optionsReducer from './options/reducer'
import repertoireReducer from './Repertoire/RepertoireReducer'
import systemNotificationsReducer from './SystemNotifications/SystemNotificationsReducer'

const rootReducer = combineReducers({
  artists: artistsReducer,
  gigRequests: gigRequestsReducer,
  options: optionsReducer,
  currentUser: currentUserReducer,
  repertoire: repertoireReducer,
  reviews: reviewsReducer,
  systemNotifications: systemNotificationsReducer,
  users: usersReducer,
})

export default rootReducer
