import { all } from 'redux-saga/effects'

import artistsSagas from './Artists/ArtistsSagas'
import gigRequestsSagas from './GigRequests/GigRequestsSagas'
import currentUserSagas from './CurrentUser/CurrentUserSagas'
import repertoireSagas from './Repertoire/RepertoireSagas'
import reviewsSagas from './Reviews/ReviewsSagas'
import systemNotificationsSagas from './SystemNotifications/SystemNotificationsSagas'
import usersSagas from './Users/UsersSagas'
import optionsSagas from './options/sagas'

function* rootSaga() {
  yield all([
    artistsSagas(),
    gigRequestsSagas(),
    currentUserSagas(),
    repertoireSagas(),
    reviewsSagas(),
    systemNotificationsSagas(),
    usersSagas(),
    optionsSagas(),
  ])
}

export default rootSaga
