export const GET_ONE_ARTIST = 'ARTISTS/GET_ONE'
export const GET_ALL_ARTISTS = 'ARTISTS/GET_ALL'
export const GET_ALL_ARTISTS_BY_STATUS = 'ARTISTS/GET_ALL_BY_STATUS'
export const GET_COUNT_ARTISTS_STATUS = 'ARTISTS/GET_COUNT'

export const FETCH_ARTIST_PENDING = 'ARTISTS/FETCH_ONE_PENDING'
export const FETCH_ARTIST_FULFILLED = 'ARTISTS/FETCH_ONE_FULFILLED'
export const FETCH_ARTIST_REJECTED = 'ARTISTS/FETCH_ONE_REJECTED'

export const FETCH_ALL_ARTISTS_PENDING = 'ARTISTS/FETCH_ALL_PENDING'
export const FETCH_ALL_ARTISTS_FULFILLED = 'ARTISTS/FETCH_ALL_FULFILLED'
export const FETCH_ALL_ARTISTS_REJECTED = 'ARTISTS/FETCH_ALL_REJECTED'

export const FETCH_COUNT_ARTISTS_STATUS_PENDING = 'ARTISTS/FETCH_COUNT_PENDING'
export const FETCH_COUNT_ARTISTS_STATUS_FULFILLED = 'ARTISTS/FETCH_COUNT_FULFILLED'
export const FETCH_COUNT_ARTISTS_STATUS_REJECTED = 'ARTISTS/FETCH_COUNT_REJECTED'

export const DECLINE_ARTIST = 'ARTISTS/DECLINE'
export const UNAPPROVE_ARTIST = 'ARTISTS/UNAPPROVE'
export const APPROVE_ARTIST = 'ARTISTS/APPROVE'
export const DRAFT_ARTIST = 'ARTISTS/DRAFT'

export const FETCH_APPROVE_ARTIST_PENDING = 'ARTISTS/FETCH_APPROVE_PENDING'
export const FETCH_APPROVE_ARTIST_FULFILLED = 'ARTISTS/FETCH_APPROVE_FULFILLED'
export const FETCH_APPROVE_ARTIST_REJECTED = 'ARTISTS/FETCH_APPROVE_REJECTED'

export const PROMOTE_ARTIST = 'ARTISTS/PROMOTE'
export const UNPROMOTE_ARTIST = 'ARTISTS/UNPROMOTE'

export const FETCH_PROMOTE_ARTIST_PENDING = 'ARTISTS/FETCH_PROMOTE_PENDING'
export const FETCH_PROMOTE_ARTIST_FULFILLED = 'ARTISTS/FETCH_PROMOTE_FULFILLED'
export const FETCH_PROMOTE_ARTIST_REJECTED = 'ARTISTS/FETCH_PROMOTE_REJECTED'
