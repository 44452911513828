/* global window FormData */
import Router from 'next/router'
import { call, put, takeEvery, takeLatest, throttle, all } from 'redux-saga/effects'

import { getJSON, postData } from '../../libs/data'
import * as types from './RepertoireTypes'
import * as actions from './RepertoireActions'

import {
  addSuccessNotification,
  addErrorNotification,
} from '../SystemNotifications/SystemNotificationsActions'

/* SONG */
function* approveRepertoireSong({ id }) {
  const data = new FormData()
  const url = '/api/v1/admin/approveRepertoireSong'
  data.append('id', id)
  try {
    yield call(postData, url, data)
    yield put(actions.approveRepertoireSongFulfilled(id))
    yield put(addSuccessNotification('Sang godkjent'))
  } catch (error) {
    yield put(addErrorNotification(error.response ? error.response.message : 'Undefined'))
  }
}

function* deleteRepertoireSong({ id }) {
  const data = new FormData()
  const url = '/api/v1/admin/deleteRepertoireSong'
  data.append('id', id)
  try {
    yield call(postData, url, data)
    yield put(actions.deleteRepertoireSongFulfilled(id))
    yield put(addSuccessNotification('Sang slettet'))
  } catch (error) {
    yield put(addErrorNotification(error.response ? error.response.message : 'Undefined'))
  }
}
/* ARTIST */

function* approveRepertoireArtist({ id }) {
  const data = new FormData()
  const url = '/api/v1/admin/approveRepertoireArtist'
  data.append('id', id)
  try {
    yield call(postData, url, data)
    yield put(actions.approveRepertoireArtistFulfilled(id))
    yield put(addSuccessNotification('Artist godkjent'))
  } catch (error) {
    yield put(addErrorNotification(error.response ? error.response.message : 'Undefined'))
  }
}

function* deleteRepertoireArtist({ id }) {
  const data = new FormData()
  const url = '/api/v1/admin/deleteRepertoireArtist'
  data.append('id', id)
  try {
    yield call(postData, url, data)
    yield put(actions.deleteRepertoireArtistFulfilled(id))
    yield put(addSuccessNotification('Artist slettet'))
  } catch (error) {
    yield put(addErrorNotification(error.response ? error.response.message : 'Undefined'))
  }
}
/* GENERIC */

function* approveReport(id, text = '', year = '') {
  const data = new FormData()
  const url = '/api/v1/admin/approveReport'
  data.append('id', id)
  if (text) {
    data.append('text', text)
  }

  if (year) {
    data.append('year', year)
  }
  yield put(actions.fetchApproveReportPending())
  try {
    const result = yield call(postData, url, data)
    yield put(actions.fetchApproveReportFulfilled(result))
    yield put(addSuccessNotification('Godkjent rapport'))
  } catch (error) {
    yield put(actions.fetchApproveReportFailed(error))
    yield put(addErrorNotification(error.response ? error.response.message : 'Undefined'))
  }
}

function* declineReport(id) {
  const data = new FormData()
  const url = '/api/v1/admin/declineReport'
  data.append('id', id)
  yield put(actions.fetchDeclineReportPending())
  try {
    const result = yield call(postData, url, data)
    yield put(actions.fetchDeclineReportFulfilled(result))
    yield put(addSuccessNotification('Avvist rapport'))
  } catch (error) {
    yield put(actions.fetchDeclineReportFailed(error))
    yield put(addErrorNotification(error.response ? error.response.message : 'Undefined'))
  }
}

/*
 * SONGS
 */

function* updateSong({ id, text, year }) {
  const data = new FormData()
  const url = '/api/v1/repertoire/editSong'
  data.append('songId', id)
  data.append('song', text)
  data.append('year', year)

  try {
    yield call(postData, url, data)
    yield put(actions.updateRepertoireSongFulfilled(id, text, year))
    yield put(addSuccessNotification('Sang endret'))
  } catch (error) {
    yield put(addErrorNotification(error.response ? error.response.message : 'Undefined'))
  }
}

function* mergeSongs({ keepSongId, replaceSongId }) {
  const url = '/api/v1/admin/mergeRepertoireSongs'
  const data = new FormData()
  data.append('keepSongId', keepSongId)
  data.append('replaceSongId', replaceSongId)

  try {
    yield call(postData, url, data)
    yield put(addSuccessNotification('Sanger slått sammen'))
    yield call(() => {
      Router.push('/repertoire/list/[type]', '/repertoire/list/songs')
    })
  } catch (error) {
    yield put(addErrorNotification(error.response ? error.response.message : 'Undefined'))
    yield call(() => {
      Router.push('/repertoire/list/[type]', '/repertoire/list/songs')
    })
  }
}

function* getReportedSongs() {
  const url = '/api/v1/admin/unprocessedReports?songs=1'
  yield put(actions.fetchReportedSongsPending())
  try {
    const data = yield call(getJSON, url, { req: null })
    yield put(actions.fetchReportedSongsFulfilled(data))
  } catch (error) {
    yield put(actions.fetchReportedSongsError(error))
  }
}

function* approveSongReport({ id }) {
  yield* approveReport(id)
  yield* getReportedSongs()
}

function* approveSongReportWithChanges({ id, text, year }) {
  yield* approveReport(id, text, year)
  yield* getReportedSongs()
}

function* declineSongReport({ id }) {
  yield* declineReport(id)
  yield* getReportedSongs()
}

function* getAllSongs({ offset, limit }) {
  const url = `/api/v1/admin/getAllRepertoireSongs?offset=${offset}&limit=${limit}`
  yield put(actions.fetchAllSongsPending(offset))
  try {
    const data = yield call(getJSON, url, { req: null })
    yield put(actions.fetchAllSongsFulfilled(offset, data))
  } catch (error) {
    yield put(actions.fetchAllSongsError(error))
  }
}

function* getArtistSongs({ artistId }) {
  const offset = 0
  const url = `/api/v1/admin/getAllRepertoireSongs?artistId=${artistId}`
  yield put(actions.fetchAllSongsPending(offset))
  try {
    const data = yield call(getJSON, url, { req: null })
    yield put(actions.fetchAllSongsFulfilled(offset, data))
  } catch (error) {
    yield put(actions.fetchAllSongsError(error))
  }
}

function* getSearchSongs({ q }) {
  const offset = 0
  const url = `/api/v1/admin/getAllRepertoireSongs?q=${q}&offset=${offset}&limit=100`
  yield put(actions.fetchAllSongsPending(offset))
  try {
    const data = yield call(getJSON, url, { req: null })
    yield put(actions.fetchAllSongsFulfilled(offset, data))
  } catch (error) {
    yield put(actions.fetchAllSongsError(error))
  }
}

/*
 * ARTIST
 */

function* updateArtist({ id, text }) {
  const url = '/api/v1/repertoire/editArtist'
  const data = new FormData()
  data.append('artistId', id)
  data.append('artist', text)

  try {
    yield call(postData, url, data)
    yield put(actions.updateRepertoireArtistFulfilled(id, text))
    yield put(addSuccessNotification('Artist endret'))
  } catch (error) {
    yield put(addErrorNotification(error.response ? error.response.message : 'Undefined'))
  }
}

function* mergeArtists({ keepArtistId, replaceArtistId }) {
  const url = '/api/v1/admin/mergeRepertoireArtists'
  const data = new FormData()
  data.append('keepArtistId', keepArtistId)
  data.append('replaceArtistId', replaceArtistId)

  try {
    yield call(postData, url, data)
    yield put(addSuccessNotification('Artister slått sammen'))
    yield call(() => {
      Router.push('/repertoire/list/[type]', '/repertoire/list/artists')
    })
  } catch (error) {
    yield put(addErrorNotification(error.response ? error.response.message : 'Undefined'))
    yield call(() => {
      Router.push('/repertoire/list/[type]', '/repertoire/list/artists')
    })
  }
}

function* getReportedArtists() {
  const url = '/api/v1/admin/unprocessedReports?artists=1'
  yield put(actions.fetchReportedArtistsPending())
  try {
    const data = yield call(getJSON, url, { req: null })
    yield put(actions.fetchReportedArtistsFulfilled(data))
  } catch (error) {
    yield put(actions.fetchReportedArtistsError(error))
  }
}

function* approveArtistReport({ id }) {
  yield* approveReport(id)
  yield* getReportedArtists()
}

function* approveArtistReportWithChanges({ id, text }) {
  yield* approveReport(id, text)
  yield* getReportedArtists()
}

function* declineArtistReport({ id }) {
  yield* declineReport(id)
  yield* getReportedArtists()
}

function* getAllArtists({ offset, limit }) {
  const url = `/api/v1/admin/getAllRepertoireArtists?offset=${offset}&limit=${limit}`
  yield put(actions.fetchAllArtistsPending(offset))
  try {
    const data = yield call(getJSON, url, { req: null })
    yield put(actions.fetchAllArtistsFulfilled(offset, data))
  } catch (error) {
    yield put(actions.fetchAllArtistsError(error))
  }
}

function* getSearchArtists({ q }) {
  const offset = 0
  const url = `/api/v1/admin/getAllRepertoireArtists?q=${q}&offset=${offset}&limit=100`
  yield put(actions.fetchAllArtistsPending(offset))
  try {
    const data = yield call(getJSON, url, { req: null })
    yield put(actions.fetchAllArtistsFulfilled(offset, data))
  } catch (error) {
    yield put(actions.fetchAllArtistsError(error))
  }
}

export default function* usersSagas() {
  yield all([
    takeLatest(types.GET_ALL_SONGS, getAllSongs),
    takeLatest(types.GET_ARTIST_SONGS, getArtistSongs),
    takeLatest(types.GET_REPORTED_SONGS, getReportedSongs),
    throttle(500, types.GET_SEARCH_SONGS, getSearchSongs),

    takeLatest(types.GET_ALL_ARTISTS, getAllArtists),
    takeLatest(types.GET_REPORTED_ARTISTS, getReportedArtists),
    throttle(500, types.GET_SEARCH_ARTISTS, getSearchArtists),

    takeEvery(types.APPROVE_ARTIST_REPORT, approveArtistReport),
    takeEvery(types.APPROVE_ARTIST_REPORT_CHANGES, approveArtistReportWithChanges),
    takeEvery(types.DECLINE_ARTIST_REPORT, declineArtistReport),

    takeEvery(types.APPROVE_SONG_REPORT, approveSongReport),
    takeEvery(types.APPROVE_SONG_REPORT_CHANGES, approveSongReportWithChanges),
    takeEvery(types.DECLINE_SONG_REPORT, declineSongReport),

    takeEvery(types.APPROVE_REPERTOIRE_ARTIST, approveRepertoireArtist),
    takeEvery(types.DELETE_REPERTOIRE_ARTIST, deleteRepertoireArtist),

    takeEvery(types.APPROVE_REPERTOIRE_SONG, approveRepertoireSong),
    takeEvery(types.DELETE_REPERTOIRE_SONG, deleteRepertoireSong),
    takeLatest(types.UPDATE_REPERTOIRE_SONG, updateSong),
    takeLatest(types.UPDATE_REPERTOIRE_ARTIST, updateArtist),
    takeLatest(types.MERGE_REPERTOIRE_ARTISTS, mergeArtists),
    takeLatest(types.MERGE_REPERTOIRE_SONGS, mergeSongs),
  ])
}
