/* global window FormData */

import { call, put, takeEvery, all, takeLatest } from 'redux-saga/effects'
import getGlobals from '../../helpers/get-globals'
import {
  addSuccessNotification,
  addErrorNotification,
} from '../SystemNotifications/SystemNotificationsActions'
import isServer from '../../helpers/is-server'

import { getJSON, postData, postJSON } from '../../libs/data'
import * as types from './UsersTypes'
import * as actions from './UsersActions'

const redirect = () => {
  if (!isServer()) {
    window.location = getGlobals().FRONTEND_SERVER_HOST
  }
}

function* getUsers(action) {
  let url = `/api/v1/admin/getUsers?q=${action.q}&sortField=${action.sortField}&sortDir=${
    action.sortDir
  }&limit=${action.limit}&offset=${action.offset}`

  if (action.role) {
    url += `&role=${action.role}`
  }

  yield put({ type: types.FETCH_ALL_USERS_PENDING })
  try {
    const users = yield call(getJSON, url, { req: null })
    yield put({ type: types.FETCH_ALL_USERS_FULFILLED, payload: { users } })
  } catch (error) {
    yield put({ type: types.FETCH_ALL_USERS_REJECTED, error })
  }
}

function* loginAs(action) {
  const url = '/api/v1/auth/logInAs'
  const data = new FormData()
  data.append('id', action.userId)
  data.append('backUrl', action.backUrl)
  yield put({ type: types.LOGIN_AS_PENDING })
  try {
    yield call(postData, url, data)
    yield put({ type: types.LOGIN_AS_SUCCESS })
    yield call(redirect)
  } catch (error) {
    yield put({ type: types.LOGIN_AS_ERROR, error })
  }
}

function* deleteUser(action) {
  const url = '/api/v1/admin/deleteUser'
  yield put(actions.deleteUserPendingAction())
  try {
    const user = yield call(postJSON, url, { id: action.id })
    yield put(actions.deleteUserFulfilledAction(user))
    yield put(addSuccessNotification('Bruker slettet'))
    yield put(actions.getUsersByRoleAction(action.role, action.sortField, action.sortDir, action.q))
  } catch (error) {
    yield put(actions.deleteUserRejectedAction(error))
    yield put(
      addErrorNotification(
        error.response && error.response.message
          ? error.response.message
          : `Uspesifisert feil fra server: ${error.statusCode}`,
      ),
    )
  }
}

export default function* usersSagas() {
  yield all([
    takeLatest(types.FETCH_ALL_USERS, getUsers),
    takeEvery(types.LOGIN_AS, loginAs),
    takeEvery(types.DELETE_USER, deleteUser),
  ])
}
