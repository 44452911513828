import * as types from './UsersTypes'

const initialState = {
  data: null,
  hasFetched: false,
  isFetching: false,
}

const usersReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.FETCH_ALL_USERS_PENDING: {
      return { ...state, isFetching: true }
    }
    case types.FETCH_ALL_USERS_FULFILLED: {
      return { ...state, data: action.payload.users, hasFetched: true, isFetching: false }
    }
    case types.FETCH_ALL_USERS_REJECTED: {
      return { ...state, data: null, hasFetched: true, isFetching: false }
    }
    default: {
      return state
    }
  }
}

export default usersReducer

export const getUsersSelector = state => state.users.data || []
export const getUsersIsLoadingSelector = state => state.users.isFetching
export const getUsersHasLoadedSelector = state => state.users.hasFetched
