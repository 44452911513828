import { call, put, takeEvery, all } from 'redux-saga/effects'
import Router from 'next/router'

import { getJSON, postJSON } from '../../libs/data'
import * as types from './ReviewsTypes'
import * as actions from './ReviewsActions'

import {
  addSuccessNotification,
  addErrorNotification,
} from '../SystemNotifications/SystemNotificationsActions'

function* getReviews() {
  const url = '/api/v1/admin/getArtistReviews'
  yield put(actions.getReviewsPendingAction())
  try {
    const reviews = yield call(getJSON, url, { req: null })
    yield put(actions.getReviewsFulfilledAction(reviews))
  } catch (error) {
    yield put(actions.getReviewsRejectedAction(error))
  }
}

function* getReviewsByStatus(action) {
  const url = `/api/v1/admin/getArtistReviews?status=${action.status}`
  yield put(actions.getReviewsPendingAction())
  try {
    const reviews = yield call(getJSON, url, { req: null })
    yield put(actions.getReviewsFulfilledAction(reviews))
  } catch (error) {
    yield put(actions.getReviewsRejectedAction(error))
  }
}

function* getOneReview(action) {
  const url = `/api/v1/admin/getOneArtistReview?id=${action.id}`
  yield put(actions.getOneReviewPendingAction())
  try {
    const review = yield call(getJSON, url, { req: null })
    yield put(actions.getOneReviewFulfilledAction(review))
  } catch (error) {
    yield put(actions.getOneReviewRejectedAction(error))
  }
}

function* deleteReview(action) {
  const url = '/api/v1/admin/deleteArtistReview'
  yield put(actions.deleteReviewPendingAction())
  try {
    const review = yield call(postJSON, url, { id: action.id })
    yield put(actions.deleteReviewFulfilledAction(review))
    yield put(addSuccessNotification('Anmeldelse slettet'))
    yield put(actions.getReviewsAction())
  } catch (error) {
    yield put(actions.deleteReviewRejectedAction(error))
    yield put(addErrorNotification(error.response ? error.response.message : 'Undefined'))
  }
}

export default function* artistsSagas() {
  yield all([
    takeEvery(types.GET_ALL_REVIEWS, getReviews),
    takeEvery(types.GET_REVIEWS_BY_STATUS, getReviewsByStatus),
    takeEvery(types.GET_ONE_REVIEW, getOneReview),
    takeEvery(types.DELETE_REVIEW, deleteReview),
  ])
}
