import * as types from './GigRequestsTypes'

export const getGigRequestsByStatusAction = (
  status,
  sortField = 'createdAt',
  sortDir = 'desc',
  limit = 300,
) => ({
  type: types.GET_ALL_GIGREQUESTS_BY_STATUS,
  status,
  sortField,
  sortDir,
  limit,
})
export const getGigRequestsAction = (sortField = 'createdAt', sortDir = 'desc', limit = 300) => ({
  type: types.GET_ALL_GIGREQUESTS,
  sortField,
  sortDir,
  limit,
})
export const getGigRequestsPendingAction = () => ({ type: types.FETCH_ALL_GIGREQUESTS_PENDING })
export const getGigRequestsSuccessAction = payload => ({
  type: types.FETCH_ALL_GIGREQUESTS_FULFILLED,
  payload,
})
export const getGigRequestsErrorAction = error => ({
  type: types.FETCH_ALL_GIGREQUESTS_REJECTED,
  error,
})

export const getGigRequestAction = id => ({
  type: types.GET_ONE_GIGREQUEST,
  id,
})
