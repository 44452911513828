import { createSelector } from 'reselect'

import * as types from './ArtistsTypes'

const initialState = {
  list: [],
  data: {},
  sortField: '',
  sortDir: '',
  q: '',
  hasFetched: false,
  isFetching: false,
  counts: {},
  details: {
    isFetching: false,
    hasFetched: false,
  },
}

const artistsReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_ALL_ARTISTS:
    case types.GET_ALL_ARTISTS_BY_STATUS: {
      return {
        ...state,
        sortField: action.sortField,
        sortDir: action.sortDir,
        q: action.q,
      }
    }
    case types.FETCH_ALL_ARTISTS_PENDING: {
      return { ...state, isFetching: true }
    }
    case types.FETCH_ALL_ARTISTS_FULFILLED: {
      const list = []
      const data = {}
      ;(action.payload.artists || []).forEach(artist => {
        list.push(artist.id)
        data[artist.id] = artist
      })
      return { ...state, list, data, hasFetched: true, isFetching: false }
    }
    case types.FETCH_ALL_ARTISTS_REJECTED: {
      return { ...state, list: null, hasFetched: true, isFetching: false }
    }
    case types.FETCH_ARTIST_PENDING: {
      return {
        ...state,
        details: {
          ...state.details,
          isFetching: true,
        },
      }
    }
    case types.FETCH_ARTIST_FULFILLED: {
      const data = {
        ...state.data,
        [action.payload.artist.id]: action.payload.artist,
      }

      return {
        ...state,
        data,
        details: {
          ...state.details,
          hasFetched: true,
          isFetching: false,
        },
      }
    }
    case types.FETCH_ARTIST_REJECTED: {
      return { ...state, list: null, details: { hasFetched: true, isFetching: false } }
    }
    case types.FETCH_COUNT_ARTISTS_STATUS_PENDING: {
      return { ...state, counts: {} }
    }
    case types.FETCH_COUNT_ARTISTS_STATUS_FULFILLED: {
      if (!action.payload) return state
      return { ...state, counts: action.payload.counts }
    }
    case types.FETCH_COUNT_ARTISTS_STATUS_REJECTED: {
      return { ...state, counts: {} }
    }
    default: {
      return state
    }
  }
}

export default artistsReducer

const getState = state => state.artists
export const getArtists = state => {
  const artistState = getState(state)
  if (artistState.list && artistState.list.length > 0) {
    return artistState.list.map(id => artistState.data[id])
  }
  return []
}

export const getArtistsIsLoading = state => state.artists.isFetching
export const getArtistsHasLoadedSelector = state => state.artists.hasFetched
export const getArtistCountsByStatus = state => state.artists.counts || {}
export const getLastSortField = state => state.artists.sortField
export const getLastSortDir = state => state.artists.sortDir
export const getLastQ = state => state.artists.q

const getOneArtist = (state, artistId) => {
  const artistState = getState(state)
  if (artistState.data && artistState.data[artistId]) {
    return artistState.data[artistId]
  }
  return null
}

export const makeGetOneArtist = () =>
  createSelector(
    [getOneArtist],
    state => state,
  )
