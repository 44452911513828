export const GET_ALL_GIGREQUESTS = 'GIGREQUESTS/GET_ALL'
export const GET_ALL_GIGREQUESTS_BY_STATUS = 'GIGREQUESTS/GET_ALL_BY_STATUS'
export const FETCH_ALL_GIGREQUESTS_PENDING = 'GIGREQUESTS/FETCH_ALL_PENDING'
export const FETCH_ALL_GIGREQUESTS_FULFILLED = 'GIGREQUESTS/FETCH_ALL_FULFILLED'
export const FETCH_ALL_GIGREQUESTS_REJECTED = 'GIGREQUESTS/FETCH_ALL_REJECTED'

export const GET_ONE_GIGREQUEST = 'GIGREQUESTS/GET_ONE'
export const FETCH_ONE_GIGREQUESTS_PENDING = 'GIGREQUESTS/FETCH_ONE_PENDING'
export const FETCH_ONE_GIGREQUESTS_FULFILLED = 'GIGREQUESTS/FETCH_ONE_FULFILLED'
export const FETCH_ONE_GIGREQUESTS_REJECTED = 'GIGREQUESTS/FETCH_ONE_REJECTED'
