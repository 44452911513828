import { call, put, takeEvery, all, delay } from 'redux-saga/effects'
import shortid from 'shortid'
import * as types from './SystemNotificationsTypes'

function* removeNotification({ id }) {
  yield put({ type: types.POP_NOTIFICATION, payload: { id } })
}

function* addNotification({ message, notifiactionType }) {
  const id = shortid.generate()
  yield put({
    type: types.UNSHIFT_NOTIFICATION,
    payload: {
      id,
      message,
      notifiactionType,
    },
  })
  yield delay(5000)
  yield* removeNotification({ id })
}

export default function* systemNotificationSagas() {
  yield all([
    takeEvery(types.NEW_NOTIFICATION, addNotification),
    takeEvery(types.REMOVE_NOTIFICATION, removeNotification),
  ])
}
