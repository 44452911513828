import fetch from 'isomorphic-fetch'
import getGlobals from '../helpers/get-globals'
import isServer from '../helpers/is-server'

const transformHeaders = headers => {
  const newHeaders = {}
  const keep = [
    'accept',
    'x-real-ip',
    'x-forwarded-for',
    'accept',
    'cookie',
    'user-agent',
    'accept-language',
    'cache-control',
  ]
  for (let i = 0; i < keep.length; i += 1) {
    if ({}.hasOwnProperty.call(headers, keep[i])) {
      newHeaders[keep[i]] = headers[keep[i]]
    }
  }
  newHeaders['x-isomorphic-proxy'] = 'true'
  return newHeaders
}

const cache = {}

/**
 * Fetch data from a given URL using either fetch in a browser or directly via Node when running on
 * the server.
 *
 * @param {String} url
 * @param {Object} [options] Optional
 * @param {String} [options.method] Default "POST"
 * @param {Object} [options.req]
 * @param {Object} [options.body]
 * @param {Number} [options.ttl]
 * @returns {Promise}
 */
export const getJSON = (url, inOptions) => {
  const options = inOptions || {}
  options.method = options.method || 'POST'
  let promise
  let cacheKey = null
  const requestUrl = url.startsWith('http') ? url : `${getGlobals().API_SERVER_HOST}${url}`
  if (isServer()) {
    let newHeaders = {}
    if (options.req && options.req.headers) {
      newHeaders = transformHeaders(options.req.headers)
    } else if (options && options.headers) {
      newHeaders = transformHeaders(options.headers)
    }

    if (options.ttl) {
      cacheKey = requestUrl

      if (cache[cacheKey] && cache[cacheKey].expires > new Date()) {
        return new Promise((resolve, reject) => {
          resolve(cache[cacheKey].data)
        })
      }
    }

    promise = fetch(requestUrl, { headers: newHeaders })
  } else {
    promise = fetch(requestUrl, {
      method: options.method,
      credentials: 'include',
      body: options.body,
      headers: {
        Accept: 'application/json',
        cache: 'no-store',
        ...(options.headers || {}),
      },
    })
  }

  return promise.then(response => {
    if (response.status !== 200) {
      return response.text().then(text => {
        let json = null
        try {
          json = JSON.parse(text)
        } catch (e) {
          // console.log('Response is not valid JSON'); //, text);
        }
        const error = new Error({
          message: `Error: getJSON received wrong status. Expected 200, got ${response.status}`,
        })
        error.response = json
        error.statusCode = response.status
        // TODO: find proper solution for bubbeling up status codes if throwing error
        if (!isServer()) {
          throw error
        }
      })
    }
    if (cacheKey !== null) {
      return response.json().then(json => {
        cache[cacheKey] = {
          expires: new Date(new Date().getTime() + options.ttl * 1000),
          data: json,
        }

        return new Promise((resolve, reject) => {
          resolve(json)
        })
      })
    }
    return response.json()
  })
}

/**
 * Post data to the server.
 *
 * @param {String} url
 * @param {Object} data
 * @returns {Promise}
 */
export const postData = (url, data) => getJSON(url, { body: data })
export const postJSON = (url, data) =>
  getJSON(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })
