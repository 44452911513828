import * as types from './UsersTypes'

export const getUsers = (
  sortField = 'firstName',
  sortDir = 'desc',
  q = '',
  limit = 300,
  offset = 0,
) => ({
  type: types.FETCH_ALL_USERS,
  sortField,
  sortDir,
  q,
  limit,
  offset,
})

export const getUsersByRoleAction = (
  role = '',
  sortField = 'firstName',
  sortDir = 'desc',
  q = '',
  limit = 300,
  offset = 0,
) => ({
  type: types.FETCH_ALL_USERS,
  role,
  sortField,
  sortDir,
  q,
  limit,
  offset,
})
export const loginAs = (userId, backUrl) => ({ type: types.LOGIN_AS, userId, backUrl })

export const deleteUserAction = (
  id,
  role = '',
  sortField = 'firstName',
  sortDir = 'desc',
  q = '',
) => ({
  type: types.DELETE_USER,
  id,
  role,
  sortField,
  sortDir,
  q,
})
export const deleteUserPendingAction = () => ({ type: types.DELETE_USER_PENDING })
export const deleteUserFulfilledAction = () => ({ type: types.DELETE_USER_FULFILLED, payload: {} }) // eslint-disable-line
export const deleteUserRejectedAction = error => ({ type: types.DELETE_USER_REJECTED, error }) // eslint-disable-line
