export const GET_ALL_REVIEWS = 'REVIEWS/GET_ALL'
export const GET_REVIEWS_BY_STATUS = 'REVIEWS/GET_BY_STATUS'
export const FETCH_ALL_REVIEWS_PENDING = 'REVIEWS/FETCH_ALL_PENDING'
export const FETCH_ALL_REVIEWS_FULFILLED = 'REVIEWS/FETCH_ALL_FULFILLED'
export const FETCH_ALL_REVIEWS_REJECTED = 'REVIEWS/FETCH_ALL_FULFILLED'

export const GET_ONE_REVIEW = 'REVIEWS/GET_ONE'
export const FETCH_ONE_REVIEW_PENDING = 'REVIEWS/FETCH_ONE_PENDING'
export const FETCH_ONE_REVIEW_FULFILLED = 'REVIEWS/FETCH_ONE_FULFILLED'
export const FETCH_ONE_REVIEW_REJECTED = 'REVIEWS/FETCH_ONE_FULFILLED'

export const DELETE_REVIEW = 'REVIEWS/DELETE'
export const DELETE_REVIEW_PENDING = 'REVIEWS/DELETE_PENDING'
export const DELETE_REVIEW_FULFILLED = 'REVIEWS/DELETE_FULFILLED'
export const DELETE_REVIEW_REJECTED = 'REVIEWS/DELETE_FULFILLED'
