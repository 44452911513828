import { createSelector } from 'reselect'
import * as types from './GigRequestsTypes'

const initialState = {
  list: [],
  data: {},
  isFetching: false,
  hasFetched: false,
  details: {
    isFetching: false,
    hasFetched: false,
  },
}

const gigRequestsReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.FETCH_ALL_GIGREQUESTS_PENDING: {
      return { ...state, isFetching: true }
    }
    case types.FETCH_ALL_GIGREQUESTS_FULFILLED: {
      const list = []
      const data = {}
      ;(action.payload.requests || []).forEach(request => {
        list.push(request.id)
        data[request.id] = request
      })
      return { ...state, list, data, hasFetched: true, isFetching: false }
    }
    case types.FETCH_ALL_GIGREQUESTS_REJECTED: {
      return { ...state, list: null, hasFetched: true, isFetching: false }
    }

    default: {
      return state
    }
  }
}

export default gigRequestsReducer

const getState = state => state.gigRequests

export const getGigRequests = state => {
  const gigState = getState(state)
  if (gigState.list && gigState.list.length > 0) {
    return gigState.list.map(id => gigState.data[id])
  }
  return []
}
export const getGigRequestsIsLoadingSelector = state => getState(state).isFetching
export const getGigRequestsHasLoadedSelector = state => getState(state).hasFetched

const getOneGigRequest = (state, gigRequestId) => {
  const gigState = getState(state)
  if (gigState.data && gigState.data[gigRequestId]) {
    return gigState.data[gigRequestId]
  }
  return null
}

export const makeGetOneGigRequest = () =>
  createSelector(
    [getOneGigRequest],
    state => state,
  )
