import { createSelector } from 'reselect'
import * as types from './ReviewsTypes'

const initialState = {
  list: {
    data: [],
    hasFetched: false,
    isFetching: false,
  },
  review: {
    data: {},
    hasFetched: false,
    isFetching: false,
  },
}

const artistsReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.FETCH_ONE_REVIEW_PENDING: {
      return {
        ...state,
        review: {
          ...state.review,
          isFetching: true,
        },
      }
    }
    case types.FETCH_ONE_REVIEW_FULFILLED: {
      return {
        ...state,
        review: {
          ...state.review,
          data: action.payload ? action.payload.review : {},
          hasFetched: true,
          isFetching: false,
        },
      }
    }
    case types.FETCH_ONE_REVIEW_REJECTED: {
      return {
        ...state,
        review: {
          ...state.review,
          data: {},
          hasFetched: true,
          isFetching: false,
        },
      }
    }
    case types.FETCH_ALL_REVIEWS_PENDING: {
      return {
        ...state,
        list: {
          ...state.list,
          isFetching: true,
        },
      }
    }
    case types.FETCH_ALL_REVIEWS_FULFILLED: {
      return {
        ...state,
        list: {
          ...state.list,
          data: action.payload.reviews,
          hasFetched: true,
          isFetching: false,
        },
      }
    }
    case types.FETCH_ALL_REVIEWS_REJECTED: {
      return {
        ...state,
        list: {
          ...state.list,
          data: [],
          hasFetched: true,
          isFetching: false,
        },
      }
    }

    default: {
      return state
    }
  }
}

export default artistsReducer

const reviewsState = state => state.reviews

export const getReviews = createSelector(reviewsState, state => state.list.data || [])
export const getReviewsIsLoading = createSelector(reviewsState, state => state.list.isFetching)
export const getReviewsHasLoaded = createSelector(reviewsState, state => state.list.hasFetched)

export const getReview = createSelector(reviewsState, state => state.review.data || {})
export const getReviewIsLoading = createSelector(reviewsState, state => state.review.isFetching)
export const getReviewHasLoaded = createSelector(reviewsState, state => state.review.hasFetched)
