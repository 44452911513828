/* eslint-disable no-underscore-dangle */
import { createStore, applyMiddleware, compose } from 'redux'
import withRedux from 'next-redux-wrapper'
import nextReduxSaga from 'next-redux-saga'
import createSagaMiddleware from 'redux-saga'

import isServer from '../helpers/is-server'
import rootReducer from './reducers'
import rootSaga from './sagas'

const composeEnhancers =
  !isServer() && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
    : compose
const bindMiddleware = middleware => applyMiddleware(...middleware)

export const configureStore = (initialState = {}) => {
  const sagaMiddleware = createSagaMiddleware()
  const store = createStore(
    rootReducer,
    initialState,
    composeEnhancers(bindMiddleware([sagaMiddleware])),
  )

  store.sagaTask = sagaMiddleware.run(rootSaga)

  return store
}

export const withReduxSaga = BaseComponent =>
  withRedux(configureStore)(nextReduxSaga(BaseComponent))
