import { call, put, takeEvery, all } from 'redux-saga/effects'

import { getJSON } from '../../libs/data'
import * as types from './CurrentUserTypes'

function* fetchMe(action) {
  const url = '/api/v1/user/me'
  yield put({ type: types.FETCH_ME_PENDING })
  try {
    const user = yield call(getJSON, url, { req: action.req })
    yield put({ type: types.FETCH_ME_FULFILLED, payload: { user } })
  } catch (error) {
    yield put({ type: types.FETCH_ME_REJECTED, error })
  }
}

export default function* currentUserSagas() {
  yield all([takeEvery(types.GET_ME, fetchMe)])
}
