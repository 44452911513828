import { call, put, takeEvery, all, select } from 'redux-saga/effects'

import { getJSON, postData } from '../../libs/data'
import * as types from './ArtistsTypes'
import * as selectors from './ArtistsReducer'
import * as actions from './ArtistsActions'

import { addSuccessNotification } from '../SystemNotifications/SystemNotificationsActions'

function* reloadArtists(status) {
  const sortField = yield select(selectors.getLastSortField)
  const sortDir = yield select(selectors.getLastSortDir)
  const q = yield select(selectors.getLastQ)
  yield put({ type: types.GET_COUNT_ARTISTS_STATUS })
  if (!status || status === 'all') {
    yield put(actions.getArtists(sortField, sortDir, q)) // import
  } else {
    yield put(actions.getArtistsByStatus(status, sortField, sortDir, q)) // import
  }
}

function* declineArtist({ id, status }) {
  const data = new FormData()
  const url = '/api/v1/admin/declineArtist'
  data.append('id', id)
  yield put({ type: types.FETCH_APPROVE_ARTIST_PENDING })
  try {
    const result = yield call(postData, url, data)
    yield put({ type: types.FETCH_APPROVE_ARTIST_FULFILLED, payload: { result } })
    yield put(addSuccessNotification('Artisten er avvist'))
    yield* reloadArtists(status)
  } catch (error) {
    yield put({ type: types.FETCH_APPROVE_ARTIST_REJECTED, error })
  }
}

function* unapproveArtist({ id, status }) {
  const data = new FormData()
  const url = '/api/v1/admin/unapproveArtist'
  data.append('id', id)
  yield put({ type: types.FETCH_APPROVE_ARTIST_PENDING })
  try {
    const result = yield call(postData, url, data)
    yield put({ type: types.FETCH_APPROVE_ARTIST_FULFILLED, payload: { result } })
    yield put(addSuccessNotification('Artisten er ikke godkjent'))
    yield* reloadArtists(status)
  } catch (error) {
    yield put({ type: types.FETCH_APPROVE_ARTIST_REJECTED, error })
  }
}

function* approveArtist({ id, status }) {
  const data = new FormData()
  const url = '/api/v1/admin/approveArtist'
  data.append('id', id)
  yield put({ type: types.FETCH_APPROVE_ARTIST_PENDING })
  try {
    const result = yield call(postData, url, data)
    yield put({ type: types.FETCH_APPROVE_ARTIST_FULFILLED, payload: { result } })
    yield put(addSuccessNotification('Artisten er godkjent og publisert'))
    yield* reloadArtists(status)
  } catch (error) {
    yield put({ type: types.FETCH_APPROVE_ARTIST_REJECTED, error })
  }
}

function* draftArtist({ id, status }) {
  const data = new FormData()
  const url = '/api/v1/admin/draftArtist'
  data.append('id', id)
  yield put({ type: types.FETCH_APPROVE_ARTIST_PENDING })
  try {
    const result = yield call(postData, url, data)
    yield put({ type: types.FETCH_APPROVE_ARTIST_FULFILLED, payload: { result } })
    yield put(addSuccessNotification('Artisten er sendt til utkast'))
    yield* reloadArtists(status)
  } catch (error) {
    yield put({ type: types.FETCH_APPROVE_ARTIST_REJECTED, error })
  }
}
function* promoteArtist({ id, status }) {
  const data = new FormData()
  const url = '/api/v1/admin/promoteArtist'
  data.append('id', id)
  yield put({ type: types.FETCH_PROMOTE_ARTIST_PENDING })
  try {
    const result = yield call(postData, url, data)
    yield put({ type: types.FETCH_PROMOTE_ARTIST_FULFILLED, payload: { result } })
    yield put(addSuccessNotification('Artisten blir nå promotert'))
    yield* reloadArtists(status)
  } catch (error) {
    yield put({ type: types.FETCH_PROMOTE_ARTIST_REJECTED, error })
  }
}

function* unpromoteArtist({ id, status }) {
  const data = new FormData()
  const url = '/api/v1/admin/unpromoteArtist'
  data.append('id', id)
  yield put({ type: types.FETCH_PROMOTE_ARTIST_PENDING })
  try {
    const result = yield call(postData, url, data)
    yield put({ type: types.FETCH_PROMOTE_ARTIST_FULFILLED, payload: { result } })
    yield put(addSuccessNotification('Artisten blir ikke lenger promotert'))
    yield* reloadArtists(status)
  } catch (error) {
    yield put({ type: types.FETCH_PROMOTE_ARTIST_REJECTED, error })
  }
}

function* getArtists(action) {
  const url = `/api/v1/admin/getArtists?q=${action.q}&sortField=${action.sortField}&sortDir=${action.sortDir}&limit=500`
  yield put({ type: types.FETCH_ALL_ARTISTS_PENDING })
  try {
    const artists = yield call(getJSON, url, { req: null })
    yield put({ type: types.FETCH_ALL_ARTISTS_FULFILLED, payload: { artists } })
  } catch (error) {
    yield put({ type: types.FETCH_ALL_ARTISTS_REJECTED, error })
  }
}

function* getArtistsByStatus(action) {
  const url = `/api/v1/admin/getArtists?q=${action.q}&sortField=${action.sortField}&sortDir=${action.sortDir}&status=${action.status}&limit=500`
  yield put({ type: types.FETCH_ALL_ARTISTS_PENDING })
  try {
    const artists = yield call(getJSON, url, { req: null })
    yield put({ type: types.FETCH_ALL_ARTISTS_FULFILLED, payload: { artists } })
  } catch (error) {
    yield put({ type: types.FETCH_ALL_ARTISTS_REJECTED, error })
  }
}

function* getArtistCountsByStatus() {
  const url = '/api/v1/admin/getArtistCountsByStatus'
  yield put({ type: types.FETCH_COUNT_ARTISTS_STATUS_PENDING })
  try {
    const counts = yield call(getJSON, url, { req: null })
    yield put({ type: types.FETCH_COUNT_ARTISTS_STATUS_FULFILLED, payload: { counts } })
  } catch (error) {
    yield put({ type: types.FETCH_COUNT_ARTISTS_STATUS_REJECTED, error })
  }
}

function* getArtistById(action) {
  const url = `/api/v1/artist/getOne?id=${action.id}`
  yield put({ type: types.FETCH_ARTIST_PENDING })
  try {
    const artist = yield call(getJSON, url, { req: null })
    yield put({ type: types.FETCH_ARTIST_FULFILLED, payload: { id: action.id, artist } })
  } catch (error) {
    yield put({ type: types.FETCH_ARTIST_REJECTED, error })
  }
}

export default function* artistsSagas() {
  yield all([
    takeEvery(types.GET_ONE_ARTIST, getArtistById),
    takeEvery(types.GET_ALL_ARTISTS, getArtists),
    takeEvery(types.GET_ALL_ARTISTS_BY_STATUS, getArtistsByStatus),
    takeEvery(types.GET_COUNT_ARTISTS_STATUS, getArtistCountsByStatus),
    takeEvery(types.DRAFT_ARTIST, draftArtist),
    takeEvery(types.APPROVE_ARTIST, approveArtist),
    takeEvery(types.UNAPPROVE_ARTIST, unapproveArtist),
    takeEvery(types.DECLINE_ARTIST, declineArtist),
    takeEvery(types.PROMOTE_ARTIST, promoteArtist),
    takeEvery(types.UNPROMOTE_ARTIST, unpromoteArtist),
  ])
}
