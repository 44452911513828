import React from 'react'
import App from 'next/app'
import Head from 'next/head'
import { compose } from 'recompose'
import { Provider } from 'react-redux'

import '../polyfills'

import { withReduxSaga } from '../store'
import { fetchMe } from '../store/CurrentUser/CurrentUserActions'
import CheckUserStatus from '../containers/check-user-status'
import { getCurrentUser } from '../store/CurrentUser/CurrentUserReducer'
import { initOptionsAction } from '../store/options/actions'
import { getOptionsLoadedSelector } from '../store/options/reducer'

class GigplanetAdminApp extends App {
  static async getInitialProps({ Component, ctx }) {
    let pageProps = {}
    const { store, req } = ctx
    if (!getCurrentUser(store.getState()).hasFetched) {
      store.dispatch(fetchMe(req || {}))
    }
    if (!getOptionsLoadedSelector(store.getState())) {
      store.dispatch(initOptionsAction(ctx.req || {}))
    }
    if (Component.getInitialProps) {
      pageProps = await Component.getInitialProps(ctx)
    }
    return { pageProps }
  }

  constructor(props) {
    super(props)
    this.state = { error: null }
  }

  componentDidCatch(error) {
    this.setState({ error })
  }

  render() {
    const { Component, pageProps, store } = this.props
    if (this.state.error) {
      return <div>FEIL</div>
    }
    return (
      <>
        <Head>
          <title>Gigplanet admin</title>
          <meta charSet="utf-8" />
          <meta name="viewport" content="initial-scale=1.0, width=device-width" />
        </Head>
        <Provider store={store}>
          <CheckUserStatus>
            <Component {...pageProps} />
          </CheckUserStatus>
        </Provider>
      </>
    )
  }
}

export default compose(withReduxSaga)(GigplanetAdminApp)
