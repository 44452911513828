import React from 'react'
import PropTypes from 'prop-types'

import { getJSON } from '../../libs/data'

import useCurrentUser from '../../hooks/useCurrentUser'

const CHECK_TIMEOUT = 120000

const CheckUserStatus = React.memo(({ children }) => {
  const loggedInRef = React.createRef(false)
  const currentUser = useCurrentUser()

  const checkStatus = () => {
    getJSON('/api/v1/user/status')
      .then(json => {
        if (window && window.location) {
          const url = `${window.location.pathname}${window.location.search}`
          const delimiter = url.indexOf('?') === -1 ? '?' : '&'
          if (loggedInRef.current !== json.loggedIn) {
            if (json.loggedIn) {
              if (url.indexOf('autoLoggedIn') === -1) {
                window.location.replace(`${url}${delimiter}autoLoggedIn=true`)
              } else {
                // window.location.replace(url)
              }
            } else if (url.indexOf('autoLoggedOut') === -1) {
              window.location.replace(`${url}${delimiter}autoLoggedOut=true`)
            } else {
              // window.location.replace(url)
            }
            return
          }
        }
        loggedInRef.current = json.loggedIn
        setTimeout(checkStatus, CHECK_TIMEOUT)
      })
      .catch(() => {
        console.log('error')
        if (window && window.location) {
          // window.location.replace(window.location.pathname)
        }
      })
  }

  React.useEffect(() => {
    loggedInRef.current = {}.hasOwnProperty.call(currentUser, 'id')
    checkStatus()
  }, [currentUser])

  return children
})

CheckUserStatus.propTypes = {
  children: PropTypes.node.isRequired,
}

export default CheckUserStatus
