export const LOGIN_AS = 'USERS/LOGIN'
export const LOGIN_AS_PENDING = 'USERS/LOGIN_PENDING'
export const LOGIN_AS_SUCCESS = 'USERS/LOGIN_SUCCESS'
export const LOGIN_AS_ERROR = 'USERS/LOGIN_ERROR'

export const FETCH_ALL_USERS = 'USERS/FETCH_ALL'
export const FETCH_ALL_USERS_PENDING = 'USERS/FETCH_ALL_PENDING'
export const FETCH_ALL_USERS_FULFILLED = 'USERS/FETCH_ALL_FULFILLED'
export const FETCH_ALL_USERS_REJECTED = 'USERS/FETCH_ALL_REJECTED'

export const DELETE_USER = 'USERS/DELETE'
export const DELETE_USER_PENDING = 'USERS/DELETE_PENDING'
export const DELETE_USER_FULFILLED = 'USERS/DELETE_FULFILLED'
export const DELETE_USER_REJECTED = 'USERS/DELETE_REJECTED'
