/* global window FormData */
import { call, put, takeEvery, all } from 'redux-saga/effects'

import { getJSON } from '../../libs/data'
import * as types from './GigRequestsTypes'
import * as actions from './GigRequestsActions'

function* getGigRequests(action) {
  const limit = action.limit || 300
  const url = `/api/v1/admin/gigRequests?sortField=${action.sortField}&sortDir=${action.sortDir}&limit=${limit}`
  yield put(actions.getGigRequestsPendingAction())
  try {
    const gigRequests = yield call(getJSON, url, { req: null })
    yield put(actions.getGigRequestsSuccessAction(gigRequests))
  } catch (error) {
    yield put(actions.getGigRequestsErrorAction(error))
  }
}
function* getGigRequestsByStatus(action) {
  const limit = action.limit || 300
  const url = `/api/v1/admin/gigRequests?status=${action.status}&sortField=${action.sortField}&sortDir=${action.sortDir}&limit=${limit}`
  yield put(actions.getGigRequestsPendingAction())
  try {
    const gigRequests = yield call(getJSON, url, { req: null })
    yield put(actions.getGigRequestsSuccessAction(gigRequests))
  } catch (error) {
    yield put(actions.getGigRequestsErrorAction(error))
  }
}

function* getGigRequest(action) {
  const url = `/api/v1/admin/gigRequests?gigRequestId=${action.id}`
  yield put(actions.getGigRequestsPendingAction())
  try {
    const gigRequests = yield call(getJSON, url, { req: null })
    yield put(actions.getGigRequestsSuccessAction(gigRequests))
  } catch (error) {
    yield put(actions.getGigRequestsErrorAction(error))
  }
}

export default function* gigRequestsSagas() {
  yield all([takeEvery(types.GET_ALL_GIGREQUESTS, getGigRequests)])
  yield all([takeEvery(types.GET_ALL_GIGREQUESTS_BY_STATUS, getGigRequestsByStatus)])
  yield all([takeEvery(types.GET_ONE_GIGREQUEST, getGigRequest)])
}
